.card{
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgb(234, 227, 210);
}

.description p{
  font-size: 14px;
}

.title{
  margin-bottom: 20px;
}

.icon{
  width: 20px;
  height: 20px;
  margin-top: 5px;
  position: relative;
}

.titleSection{
  display: flex;
  flex-direction: row;
  gap: 10px;
}