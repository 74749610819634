.container {
  display: grid;
  grid-gap: 60px 30px;
}

.mobile-columns-1 {
  grid-template-columns: repeat(1, 1fr);
}

.mobile-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}

.mobile-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}

.mobile-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}

.mobile-columns-5 {
  grid-template-columns: repeat(5, 1fr);
}

.mobile-columns-6 {
  grid-template-columns: repeat(6, 1fr);
}

@media screen and (min-width: 768px) {
  .tablet-columns-1 {
    grid-template-columns: repeat(1, 1fr);
  }

  .tablet-columns-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .tablet-columns-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .tablet-columns-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  .tablet-columns-5 {
    grid-template-columns: repeat(5, 1fr);
  }

  .tablet-columns-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .desktop-columns-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  
  .desktop-columns-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .desktop-columns-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  
  .desktop-columns-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  
  .desktop-columns-5 {
    grid-template-columns: repeat(5, 1fr);
  }
  
  .desktop-columns-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}


.image {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.25%;
}

.header {
  font-size: 24px;
}

.link {
  margin-top: 20px;
}

.item {
  border-bottom: 1px solid var(--color-grey-light);
  padding-bottom: 30px;
}